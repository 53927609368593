<script setup>
import {getLogo} from "@/helpers/logo";
import AddToTrackers from "@/components/manage/SelectTrackers.vue";
import SimilarStartups from "@/components/startups/SimilarStartups.vue";
import {useStore} from "vue2-helpers/vuex";
import {ref, inject, watch} from "vue";
import ExportPdf from "@/components/export/ExportPdf.vue";
import exportsService from "@/services/exports.service";
import fileHelper from "@/helpers/file";
import accountTypes, {ACCOUNT_TYPE_PREMIUM} from "@/constants/accountTypes";
import TrackersListModal from "@/components/trackers/TrackersListModal.vue";
import TasksList from "@/components/tasks/TasksListModal.vue";
import StartChatPopup from "@/views/messages/StartChatPopup.vue";

const {commonData} = defineProps(['commonData']);
const emit = defineEmits(['getLatestActivities','reportIncorrectData','inviteCompany']);
const startupId = inject('startupId');
const getTasksCountMethod = inject('getTasksCount');
const getTrackersIdsMethod = inject('getTrackersIds');
const addToTrackersMethod = inject('addToTrackers');
const getAvailableRecipientsMethod = inject('getAvailableRecipients');
const store = useStore();

const trackersIds = ref(null);
const addToTrackersModal = ref(null);
const similarStartupsModal = ref(null);
const exportPdfModal = ref(null);
const trackersListModal = ref(null);
const tasksListModal = ref(null);
const startChatModal = ref(null);
const availableRecipients = ref([]);
const reopenTrackersModal = ref(false);
const tasksCount = ref(commonData.tasks_count);
const showActions = ref(false);

watch(() => store.getters.isEnterprise, (value) => {
    if (value) {
        getTrackersIds();
        getAvailableRecipients();
    }
}, {immediate: true});

async function getTrackersIds() {
    const {data} = await getTrackersIdsMethod();
    trackersIds.value = data;
}

async function getAvailableRecipients() {
    const {data} = await getAvailableRecipientsMethod();
    availableRecipients.value = data;
}

async function addToTrackers(addedTrackers) {
    let trackersIds = addedTrackers.map(item =>item.id);
    await addToTrackersMethod(trackersIds);
    getTrackersIds();
    trackersListModal.value.updateTrackers();
    emit('getLatestActivities');
}

async function exportPPT(advanced = false) {
    const {data} = await exportsService.generateStartupsPpt(advanced, [startupId]);
    fileHelper.download(data, commonData.name + '.pptx')
}

async function exportContacts() {
    const {data} = await exportsService.exportCompaniesContacts(accountTypes.STARTUP, [startupId]);
    fileHelper.download(data, 'contacts.csv');
}

function showTasks() {
    tasksListModal.value.open();
}

function addTrackerFromModal() {
    reopenTrackersModal.value = true;
    addToTrackersModal.value.open();
}

function addToTrackersClosed() {
    if (reopenTrackersModal.value) {
        reopenTrackersModal.value = false;
        trackersListModal.value.open();
    }
}

async function getTasksCount() {
    const {data} = await getTasksCountMethod();
    tasksCount.value = data;
}

function trackersClicked() {
    if (trackersIds.value.length) {
        trackersListModal.value.open();
    } else {
        addToTrackersModal.value.open();
    }
}

</script>

<template>
    <div class="topbar topbar--primary">
        <div class="image-block-wrapper">
            <div class="image-block image-block--lg">
                <img :src="getLogo(commonData.logo_url)" alt="img"/>
            </div>

            <div>
                <div class="d-flex mb-2 pt-lg-3">
                    <h2 class="heading2 mb-0">{{commonData.name}}</h2>

                    <a
                        v-if="store.getters.isEnterprise && trackersIds !== null"
                        class="counter"
                        @click="trackersClicked"
                    >{{trackersIds.length ? trackersIds.length : '+'}}</a>
                </div>

                <template v-if="commonData.has_account && availableRecipients.length && !store.state.user.settings.disable_external_messaging">
                    <StartChatPopup
                        ref="startChatModal"
                        :recipients="availableRecipients"
                    />

                    <div class="d-flex">
                        <!-- <a class="link-follow mr-4">Follow</a> -->
                        <a class="link-message" @click="startChatModal.open()">
                            Message
                        </a>
                    </div>
                </template>
            </div>
        </div>

        <div class="topbar__mobile-fit" v-click-outside="() => showActions = false">
    

    <!-- <input type="checkbox" name="checkbox" /> -->


            <input type="checkbox" v-model="showActions" class="topbar__mobile-fit__switcher"/>
            <div class="mt-lg-4 topbar__inner">

                <ul class="actions-list ml-auto">
                    <li v-if="store.getters.isEnterprise" class="actions-list__similar"><a @click="similarStartupsModal.open(startupId)">Similar Startups</a></li>
                    <li v-if="store.getters.isEnterprise" class="actions-list__task"><a @click="showTasks">Manage Tasks ({{tasksCount}})</a></li>
                </ul>

                <ul class="topbar__side ml-4">
                    <li>
                        <b-dropdown text="Export" class="topbar__side__actions topbar__side__actions--export">
                            <b-dropdown-item class="action" @click="exportPdfModal.open([startupId], commonData.name)">
                                <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                <a>Export PDF</a>
                            </b-dropdown-item>

                            <template v-if="store.getters.isEnterprise">
                                <b-dropdown-item class="action" @click="exportPPT(false)">
                                    <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a>Export PPT</a>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="store.getters.settings.allow_advanced_ppt_export" class="action" @click="exportPPT(true)">
                                    <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a>Export PPT (Advanced)</a>
                                </b-dropdown-item>

                                <b-dropdown-item class="action" @click="exportContacts">
                                    <font-awesome-icon :icon="['fa', 'file-csv']" />
                                    <a>Export Contacts CSV</a>
                                </b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </li>

                    <li v-if="store.getters.isEnterprise">
                        <b-dropdown text="More" class="topbar__side__actions">
                            <b-dropdown-item class="action icons-list__report">
                                <a @click="emit('reportIncorrectData')">Report Incorrect Data</a>
                            </b-dropdown-item>

                            <b-dropdown-item class="action icons-list__invite" v-if="store.state.accountType === ACCOUNT_TYPE_PREMIUM">
                                <a @click="emit('inviteCompany')">Invite Startup To Edit Profile</a>
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>
            </div>
        </div>

        <AddToTrackers
            v-if="trackersIds !== null"
            ref="addToTrackersModal"
            :trackers-list="store.getters.trackersList"
            :exclude-trackers-list="trackersIds"
            @trackersSelected="addToTrackers"
            @modalClosed="addToTrackersClosed"
        />

        <SimilarStartups ref="similarStartupsModal"/>

        <ExportPdf
            ref="exportPdfModal"
            table-type="profile"
        />

        <TrackersListModal
            ref="trackersListModal"
            @addToTracker="addTrackerFromModal"
            @trackerRemoved="getTrackersIds"
        />

        <TasksList
            ref="tasksListModal"
            @tasksChanged="getTasksCount"
        />
    </div>
</template>

<style scoped>

</style>
