<script setup>
import {getShortFullName} from "@/helpers/string";
import {decodeDate} from "@/helpers/date";
import Avatar from "@/components/ui/Avatar.vue";
import VClamp from "vue-clamp";
import {ref, watch} from "vue";
import InteractionEditModal from "@/components/interactions/InteractionEditModal.vue";
import actionLogsService from "@/services/action-logs.service";
import ArticleEditModal from "@/components/articles/ArticleEditModal.vue";
import articlesService from "@/services/articles.service";

const props = defineProps(['activities','isPreview','loading']);
const emit = defineEmits(['editModalOpened','editModalClosed','activitiesChanged']);

const localActivities = ref(null);
const interactionEditModal = ref(null);
const articleEditModal = ref(null);

watch(() => props.activities, (value) => {
    localActivities.value = value?.map(activity => ({
        ...activity,
        clamped: activity.custom_value?.raw_value?.length > 200,
        bigText: activity.custom_value?.raw_value?.length > 200
    })) || null;
}, {immediate: true});

function editActivity(activity) {
    emit('editModalOpened');

    if (activity.type.includes('note')) {
        interactionEditModal.value.openEditNote(activity.object_id)
    } else {
        interactionEditModal.value.openEditInteraction(activity.object_id);
    }
}

async function removeActivity(activity) {
    if (confirm("Remove activity?")) {
        await actionLogsService.removeActivity(activity.log_id);
        emit('activitiesChanged');
    }
}

async function removeArticle(id) {
    if (confirm("Remove article?")) {
        await articlesService.delete(id).then(() => {
            emit('activitiesChanged');
        })
    }
}

</script>

<template>
    <div>
        <div v-if="loading" class="loading__spinner">
            <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
        </div>

        <template v-if="localActivities !== null">


            <ul v-if="localActivities.length" class="activity-list action-logs-list">
                <li class="d-flex" v-for="(activity, index) in localActivities" :key="index">
                    <div class="activity-list__wrapper">
                        <div class="activity-list__author">
                            <Avatar v-if="activity.user_name === 'SwitchPitch'"
                                    background-color="#043648"
                                    color="#00AFEF"
                                    initials="SP"
                                    :size="42"
                            />

                            <Avatar v-else
                                    :username="activity.user_name"
                                    :src="activity.user_avatar"
                                    :size="42"
                            />

                            <div>
                                <h5 class="heading5 heading5--secondary" :class="{'deleted': activity.user_deleted}">
                                    <template v-if="isPreview">
                                        {{ getShortFullName(activity.user_name) }}
                                    </template>

                                    <template v-else>
                                        {{ activity.user_name }}
                                    </template>
                                    <template v-if="activity.user_deleted">(D)</template>
                                </h5>

                                <time class="activity-list__time">{{ activity.date }}</time>
                            </div>
                        </div>

                        <div class="activity-list__content">
                            <div class="activity-list__content__inner">
                                <h5
                                    class="activity-list__title mb-2"
                                    :class="`activity-list__title--${activity.type === 'phone_call' ? 'call' : activity.type}`"
                                >
                                    {{ activity.title }}
                                    <template v-if="activity.interaction_date">
                                        <span style="font-weight: normal;" class="ml-2">(Interaction Date: {{decodeDate(activity.interaction_date)}})</span>
                                    </template>

                                    <template v-if="activity.custom_value?.field">
                                        :<span style="font-weight: normal;" class="ml-2">{{activity.custom_value.field}}</span>
                                    </template>
                                </h5>

                                <div class="tags-light-list">
                                    <template v-if="!isPreview">
                                        <span v-for="tag in activity.tags" :key="tag" class="tags-light-list__tag">{{tag}}</span>
                                    </template>
                                </div>
                            </div>

                            <!-- Only use v-clamp for case if note doesn't contain mention, otherwise we should use v-html statement -->
                            <template v-if="!activity.text.includes('<span') && activity.object_type !== 'news' && activity.type !== 'update_location'">
                                <v-clamp autoresize :max-lines="2" class="activity-list__content__text">
                                    {{activity.text}}

                                    <template #after="{ toggle, expanded, clamped }">
                                        <a v-if="expanded" class="link-normal" @click="toggle">less</a>
                                        <a v-if="clamped" class="link-normal" @click="toggle">more</a>
                                    </template>
                                </v-clamp>
                            </template>

                            <template v-else>
                                <div class="activity-list__text" v-html="activity.text"></div>
                            </template>

                            <template v-if="activity.tag">
                                <div class="tags-list tags-list--light">
                                    <div class="tags-list__tag">
                                        <template v-if="typeof activity.tag === 'object'">
                                            <a :id="'tag-' + index + '-' + activity.tag.id" :href="'/startups?tag=' + activity.tag.name" target="_blank">{{activity.tag.name}}</a>
                                            <b-tooltip :target="'tag-' + index + '-' + activity.tag.id" placement="right" variant="light" :title="activity.tag.description"/>
                                        </template>

                                        <template v-else>
                                            <a :href="'/startups?tag=' + activity.tag" target="_blank">{{activity.tag}}</a>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <template v-if="activity.tracker">
                                <div>
                                    <a class="action-logs-list__item"
                                       :href="'/trackers/' + activity.tracker.id"
                                       target="_blank">{{activity.tracker.name}}
                                    </a>
                                </div>
                            </template>

                            <template v-if="activity.contact">
                                <div class="action-logs-list__user-inner">
                                    {{activity.contact}}
                                </div>
                            </template>

                            <template v-if="activity.task">
                                <div class="action-logs-list__task">
                                    <a :href="'/?open_task=' + activity.task.id" target="_blank">{{activity.task.name}}</a>
                                </div>
                            </template>

                            <template v-if="activity.custom_value">
                                <div class="action-logs-list__custom">
                                    <template v-if="activity.custom_value.value.length">
                                        <v-clamp
                                            v-if="activity.clamped"
                                            autoresize :max-lines="3"
                                        >
                                            {{activity.custom_value.value}}

                                            <template #after="{ clamped }">
                                                <a v-if="clamped" class="link-normal" @click="localActivities[index].clamped = false"> more</a>
                                            </template>
                                        </v-clamp>

                                        <template v-else>
                                            <div v-html="activity.custom_value.raw_value"></div>

                                            <a
                                                v-if="activity.bigText"
                                                class="link-normal"
                                                @click="localActivities[index].clamped = true">
                                                less
                                            </a>
                                        </template>
                                    </template>
                                </div>
                            </template>

                            <template v-if="activity.file">
                                <div class="action-logs-list__file-wrapper">
                                    <div class="action-logs-list__file">
                                        {{ activity.file }}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="dropdown-original-wrapper">
                    <template v-if="activity.can_edit">
                        <b-dropdown
                            v-if="activity.object_type === 'news'"
                            right
                            class="dropdown-single icons-list dropdown-original dropdown-original--small"
                        >
                            <b-dropdown-item class="icons-list__edit" @click="articleEditModal.open(activity.object_id)">
                                Edit Article
                            </b-dropdown-item>

                            <b-dropdown-item class="icons-list__delete2" @click="removeArticle(activity.object_id)">
                                Delete Article
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown
                            v-else
                            right
                            class="dropdown-single icons-list dropdown-original dropdown-original--small"
                        >
                            <b-dropdown-item
                                class="icons-list__edit"
                                @click="editActivity(activity)"
                            >
                                Edit
                            </b-dropdown-item>

                            <b-dropdown-item
                                class="icons-list__delete2"
                                @click="removeActivity(activity)"
                            >
                                Remove
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    </div>
                </li>
            </ul>

            <div v-else class="activity-list-empty">
                <div class="main-startup__tabs__item__inner">
                    <h5 class="heading5 heading5--secondary">No Interactions… Yet.</h5>

                    <ul class="activity-icons-list">
                        <li class="activity-icons-list__call"></li>
                        <li class="activity-icons-list__note"></li>
                        <li class="activity-icons-list__email"></li>
                        <li class="activity-icons-list__demo"></li>
                        <li class="activity-icons-list__meeting"></li>
                    </ul>

                    <p>Calls, notes, emails, demos and meetings logged will display here, along with profile views by users in your company.</p>
                </div>
            </div>
        </template>

        <InteractionEditModal
            ref="interactionEditModal"
            @saved="emit('activitiesChanged')"
            @closed="emit('editModalClosed')"
        />

        <ArticleEditModal
            ref="articleEditModal"
            @saved="emit('activitiesChanged')"
        />
    </div>
</template>

<style scoped>

</style>
