import Trackers from '../views/trackers/Trackers'
import Tracker from '../views/trackers/Tracker'
import TrackerFolder from '../views/trackers/TrackerFolder'
import PublicTrackers from '../views/trackers/PublicTrackers'
import PublicTracker from '../views/trackers/PublicTracker'
import TrackerPreview from '../views/trackers/TrackerPreview'
import PublicCollection from '../views/trackers/PublicCollection'

export default [
    {
        path: '/trackers',
        component: Trackers,
        meta: {
            access: ['enterprise','premiumEcosystem'],
            title: 'Trackers'
        }
    },
    {
        path: '/trackers/:id',
        component: Tracker,
        meta: {
            access: ['enterprise','premiumEcosystem']
        }
    },
    {
        path: '/trackers/folder/:id',
        component: TrackerFolder,
        meta: {
            access: ['enterprise','premiumEcosystem']
        }
    },
    {
        path: '/trackers/:id/preview',
        component: TrackerPreview,
        meta: {
            access: ['enterprise']
        }
    },
    {
        path: '/public-trackers',
        component: PublicTrackers,
        meta: {
            access: ['startup'],
            title: 'Trackers',
        }
    },
    {
        path: '/public-trackers/:id',
        component: PublicTracker,
        meta: {
            guest: true
        }
    },
    {
        path: '/public-collections/:hash',
        component: PublicCollection,
        meta: {
            guest: true
        }
    },
]
