<template>
    <div>
        <b-modal :id="modalName" modal-class="start-chat-modal modal-w-middle" title="Select Users For Chat" @hide="onCloseModal">
            <p>Select one user for a 1-1 thread or start a group chat by selecting multiple users.</p>

            <ul class="select-chat-list">
                <li v-for="recipient in recipientsList" :key="recipient.id">
                    <div class="input-checkbox">
                        <input :id="'t'+recipient.id" type="checkbox" :value="recipient"
                               v-model="selectedRecipients">
                        <label :for="'t'+recipient.id"></label>
                    </div>

                    <div class="image-block image-block--rounded image-block--rounded--46">
                        <Avatar v-if="recipient.avatar || recipient.name "
                                :username="recipient.name"
                                :src="recipient.avatar"
                                :size="46"
                        />
                    </div>
                    <div>
                        <h4 class="heading4"><template>{{ recipient.name ?? recipient.email }}</template></h4>
                        <p>{{ recipient.job_title }}</p>
                    </div>
                </li>
            </ul>

            <template #modal-footer>
                <p>{{ selectedCountLabel }}</p>

                <b-button @click="startChat" class="button" :disabled="!canStart">
                    Start Chat
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'
    import ecosystems from "@/services/ecosystems.service"
    import startups from "@/services/startups.service"

    export default {
        name: 'StartChatPopup',
        components: {
            Avatar: () => import("@/components/ui/Avatar.vue")
        },
        props: {
            modalName: {type: String, default: () => "startChatModal"},
            recipients: {type: Array, default: () => []},
            contactsType: {
                type: String,
                validator: propValue => {
                    if (propValue) {
                        const listOfAvailableTypes = ["startup", "ecosystem"]

                        return listOfAvailableTypes.some(type => propValue === type)
                    }

                    return true;
                }
            }
        },
        data() {
            return {
                contactsEntityId: 0,
                recipientsList: [],
                selectedRecipients: [],
                receiver: null,
            }
        },
        watch: {
            recipients: {
                immediate: true,
                handler(value) {
                    this.recipientsList = value;
                }
            },
            contactsEntityId() {
                this.recipientsList = []
            }
        },
        computed: {
            canStart() {
                return this.selectedRecipients.length
            },
            selectedCountLabel() {
                let label = "No users selected";
                if (this.selectedRecipients.length) {
                    label = this.selectedRecipients.length + (this.selectedRecipients.length === 1 ? ' user ' : ' users ') + 'selected'
                }

                return label
            },
            contactsService() {
                return this.contactsType === 'ecosystem' ? ecosystems : startups
            },
        },
        methods: {
            ...mapMutations(['addNewRoom']),
            open(contactsEntityId = 0, receiver = null) {
                this.contactsEntityId = contactsEntityId
                this.receiver = receiver
                this.selectedRecipients = []

                if (!this.recipients.length) {
                    this.getRecipients()
                }
                else {
                    this.processRecipients()
                }
            },
            close() {
                this.$bvModal.hide(this.modalName)
            },
            deselectAll() {
                this.selectedRecipients = []
            },
            onCloseModal() {
                this.deselectAll();
            },
            startChat() {
                this.addNewRoom(this.selectedRecipients)
                this.close()
            },
            getRecipients() {
                this.contactsService.getAvailableRecipients(this.contactsEntityId).then(response => {
                    this.recipientsList = response.data
                    this.processRecipients()
                })
            },
            processRecipients() {
                if (this.recipientsList.length === 1) {
                    if (!this.selectedRecipients.some(item =>item.id === this.recipientsList[0].id)) {
                        this.selectedRecipients.push(this.recipientsList[0])
                    }
                    this.startChat()
                }
                else {
                    if (this.receiver) {
                        const receiver = this.recipientsList.find(item => item.id === this.receiver)

                        if (receiver) {
                            this.selectedRecipients.push(receiver)
                        }
                    }

                    this.$bvModal.show(this.modalName)
                }
            }
        }
    }
</script>
