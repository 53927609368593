import { render, staticRenderFns } from "./ProfileSideBar.vue?vue&type=template&id=9f4641c6&scoped=true&"
import script from "./ProfileSideBar.vue?vue&type=script&setup=true&lang=js&"
export * from "./ProfileSideBar.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f4641c6",
  null
  
)

export default component.exports